<template>
  <v-card
    flat
    :class="{ 'd-grid': mdAndUp }"
    class="ticket-management-supports"
  >
    <v-card-text class="px-0">
      <Split
        style="min-height: 100vh"
        :direction="!mdAndUp ? 'vertical' : 'horizontal'"
      >
        <SplitArea :size="65" :min-size="350" class="border-1 py-0 rounded-10">
          <v-sheet v-if="handler_tickets" width="100%" height="100vh">
            <v-slide-group
              class="pa-1 fill-height"
              active-class="success"
              show-arrows
            >
              <v-slide-item
                v-for="(dev, index) in handler_tickets"
                :key="dev.id"
                class="mr-1"
              >
                <v-card outlined width="300">
                  <v-card-title class="fs-16 primary py-2 light--text">
                    <v-avatar size="30" class="mr-2">
                      <v-img :src="dev.image_url"></v-img>
                    </v-avatar>
                    {{ dev.fullname }}
                  </v-card-title>
                  <v-card-text class="px-1">
                    <v-list dense>
                      <draggable
                        v-model="handler_tickets[index].assigned"
                        :list="dev.assigned"
                        style="height: 80vh"
                        class="fullwidth"
                        draggable=".draggable-item"
                        group="ticket-group"
                        @change="handleDragChange($event, dev.id)"
                      >
                        <template v-if="dev.assigned.length">
                          <v-hover
                            v-slot:default="{ hover }"
                            v-for="ticket in dev.assigned"
                            :key="ticket.id"
                          >
                            <v-list-item
                              @click.stop="prepareView(ticket)"
                              :class="{ primary: hover }"
                              class="
                                draggable-item
                                mb-1
                                rounded-5
                                border-1
                                cursor-drag
                              "
                            >
                              <v-list-item-content
                                :class="[
                                  hover ? 'light--text' : 'primary--text'
                                ]"
                              >
                                <v-list-item-title>
                                  {{ ticket.slug }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ ticket.title | ucwords }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action-text class="fw-700 fs-11">
                                <v-chip
                                  x-small
                                  outlined
                                  class="status-chip"
                                  :class="slugify(ticket.status)"
                                >
                                  {{ ticket.status | ucwords }}
                                </v-chip>
                              </v-list-item-action-text>
                            </v-list-item>
                          </v-hover>
                        </template>
                        <Empty
                          min-height="80vh"
                          headline="No assigned task yet.<br>Drop ticket here"
                          v-else
                        ></Empty>
                      </draggable>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
          <Empty
            v-else
            headline="No handlers found!"
            min-height="100vh"
          ></Empty>
        </SplitArea>
        <SplitArea :size="35" :min-size="420" class="border-1 rounded-10 px-1">
          <v-card flat>
            <v-card-title class="py-0">
              <v-tabs
                hide-slider
                v-model="right_tab"
                height="40"
                dense
                fixed-tabs
              >
                <v-tab href="#unassigned" class="fw-800 text-none">
                  Unassigned Tickets
                </v-tab>
                <v-tab href="#closed" class="fw-800 text-none">
                  Closed Tickets
                </v-tab>
              </v-tabs>
            </v-card-title>
            <v-tabs-items v-model="right_tab">
              <v-tab-item value="unassigned">
                <v-list dense>
                  <draggable
                    :list="unassigned_tickets"
                    class="list-group"
                    style="height: 80vh"
                    draggable=".draggable-item"
                    group="ticket-group"
                  >
                    <template v-if="unassigned_tickets.length">
                      <v-hover
                        v-slot:default="{ hover }"
                        v-for="ticket in unassigned_tickets"
                        :key="ticket.id"
                      >
                        <v-list-item
                          @click.stop="prepareView(ticket)"
                          :class="{ primary: hover }"
                          class="
                            draggable-item
                            mb-1
                            rounded-5
                            border-1
                            cursor-drag
                          "
                        >
                          <v-list-item-content
                            :class="[hover ? 'light--text' : 'primary--text']"
                          >
                            <v-list-item-title>
                              {{ ticket.slug }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ ticket.title | ucwords }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action-text
                            class="fw-700 fs-11 status-chip"
                          >
                            <v-chip
                              small
                              outlined
                              class="status-chip"
                              :class="slugify(ticket.status)"
                            >
                              {{ ticket.status | ucwords }}
                            </v-chip>
                          </v-list-item-action-text>
                        </v-list-item>
                      </v-hover>
                    </template>
                    <Empty
                      min-height="80vh"
                      v-else
                      headline="No more unassigned ticket"
                    ></Empty>
                  </draggable>
                  <v-subheader v-if="unassigned_tickets_next_page_url">
                    <v-btn
                      depressed
                      block
                      @click="fetchMoreUnassignedTickets"
                      :disabled="fetching"
                      :loading="fetching"
                      color="primary"
                      class="text-none mx-auto"
                    >
                      Load more
                    </v-btn>
                  </v-subheader>
                </v-list>
              </v-tab-item>
              <v-tab-item value="closed">
                <v-list dense v-if="closed_tickets.length">
                  <v-list-item
                    @click.stop="prepareView(ticket)"
                    class="mb-1 rounded-5 border-1"
                    v-for="ticket in closed_tickets"
                    :key="ticket.id"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        {{ ticket.title | ucwords }}
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ ticket.slug }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action-text
                      class="secondary--text"
                      v-if="ticket.assigned.length"
                    >
                      Assigned: {{ ticket.assigned[0].fullname | ucwords }}
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-subheader v-if="closed_tickets_next_page_url">
                    <v-btn
                      depressed
                      block
                      @click="fetchMoreClosedTickets"
                      :disabled="fetching"
                      :loading="fetching"
                      color="primary"
                      class="text-none mx-auto"
                    >
                      Load more
                    </v-btn>
                  </v-subheader>
                </v-list>
                <Empty
                  min-height="80vh"
                  v-if="!closed_tickets.length"
                  headline="No closed ticket yet"
                ></Empty>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </SplitArea>
      </Split>
    </v-card-text>

    <ViewTicketDialog v-model="open_view_ticket"></ViewTicketDialog>
  </v-card>
</template>

<script>
import store from '@/store/store'
import { support_mixin } from '../support_mixin'
import draggable from 'vuedraggable'
import request from '@/services/axios_instance'
import { mapActions, mapMutations } from 'vuex'
import ViewTicketDialog from '../components/ViewTicketDialog.vue'
export default {
  mixins: [support_mixin],
  components: {
    draggable,
    ViewTicketDialog
  },
  created() {
    this.fetchUnassignedTickets()
    this.fetchHandlerTickets()
    this.fetchClosedTickets()
    this.$event.$on('handlers-updated', () => {
      this.fetchUnassignedTickets()
      this.fetchHandlerTickets()
    })
  },
  data: () => ({
    open_view_ticket: false,
    right_tab: 'unassigned',
    fetching: false,
    handler_tickets: [],
    unassigned_tickets: [],
    unassigned_tickets_next_page_url: null,
    closed_tickets: [],
    closed_tickets_next_page_url: null
  }),
  methods: {
    ...mapMutations('supports', ['set_activeTicket']),
    ...mapActions('supports', ['assignTicketTo', 'unassignTicketFrom']),
    handleDragChange(event, user_id) {
      if (event.hasOwnProperty('moved')) return
      if (event.hasOwnProperty('added')) {
        this.assignTicketTo({
          id: event.added.element.id,
          user_id: user_id,
          cb: () => {}
        })
      } else if (event.hasOwnProperty('removed')) {
        this.unassignTicketFrom({
          id: event.removed.element.id,
          user_id: user_id,
          cb: () => {}
        })
      }
    },
    fetchUnassignedTickets() {
      this.fetching = true
      request
        .get(`api/supports?unassigned=1`)
        .then(({ data }) => {
          this.unassigned_tickets = data.data
          this.unassigned_tickets_next_page_url = data.next_page_url
        })
        .finally(() => (this.fetching = false))
    },
    fetchMoreUnassignedTickets() {
      this.fetching = true
      request
        .get(this.unassigned_tickets_next_page_url)
        .then(({ data }) => {
          this.unassigned_tickets.push(...data.data)
          this.unassigned_tickets_next_page_url = data.next_page_url
        })
        .finally(() => (this.fetching = false))
    },
    fetchClosedTickets() {
      this.fetching = true
      request
        .get(`api/supports?status=Closed`)
        .then(({ data }) => {
          this.closed_tickets = data.data
          this.closed_tickets_next_page_url = data.next_page_url
        })
        .finally(() => (this.fetching = false))
    },
    fetchMoreClosedTickets() {
      this.fetching = true
      request
        .get(this.closed_tickets_next_page_url)
        .then(({ data }) => {
          this.closed_tickets.push(...data.data)
          this.closed_tickets_next_page_url = data.next_page_url
        })
        .finally(() => (this.fetching = false))
    },
    fetchHandlerTickets() {
      this.fetching = true
      request
        .get(`api/supports/handlers?tickets=1&except_closed=1`)
        .then(({ data }) => {
          this.handler_tickets = data
        })
        .finally(() => (this.fetching = false))
    },
    prepareView(item) {
      this.set_activeTicket(item)
      this.open_view_ticket = true
    }
  },
  beforeRouteEnter(to, from, next) {
    return store.dispatch('supports/fetchHandlers').then(() => {
      const handlers = store.getters['supports/handlers'].map((i) => i.id)
      if (
        store.getters.user.is_super_admin ||
        handlers.includes(store.getters.user.id)
      ) {
        next()
      } else next('/404')
    })
  }
}
</script>

<style lang="scss" scoped>
.status-chip {
  &.in-progress {
    color: blue;
    border-color: blue;
  }
  &.open {
    color: rgb(8, 123, 177);
    border-color: rgb(8, 123, 177) !important;
  }
  &.closed {
    color: $red;
    border-color: $red;
  }
  &.archived {
    color: maroon;
    border-color: maroon;
  }
  &.fixed {
    color: darkgreen;
    border-color: darkgreen;
  }
}
</style>
