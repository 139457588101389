<template>
  <CustomDialog
    v-model="dialog"
    :title="title"
    :has-footer="false"
    max-width="800"
  >
    <template v-slot:content>
      <ViewTicket
        v-if="activeTicket"
        :key="activeTicket.id"
        flat
        class="fill-height"
        :item="activeTicket"
      ></ViewTicket>
      <Empty v-else headline="Reselect ticket"></Empty>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import _ from 'lodash'
import ViewTicket from './ViewTicket.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'ViewTicketDialog',
  components: { CustomDialog, ViewTicket },
  props: {
    value: { type: Boolean, default: false }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    ...mapGetters('supports', ['activeTicket']),
    title() {
      return this.activeTicket ? this.activeTicket.title : ''
    }
  },
  watch: {
    value(val) {
      this.dialog = val
    },
    dialog(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    ...mapMutations('supports', ['set_activeTicket']),
    close() {
      this.dialog = false
      this.set_activeTicket(null)
    }
  }
}
</script>
<style></style>
